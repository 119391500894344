var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { size: "mini", placeholder: "名称" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter.apply(null, arguments)
                },
              },
              model: {
                value: _vm.listQuery.key,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "key", $$v)
                },
                expression: "listQuery.key",
              },
            }),
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "filter-item",
                attrs: { size: "mini", icon: "el-icon-search" },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("搜索")]
            ),
            _c("permission-btn", {
              attrs: { size: "mini" },
              on: { "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c(
          "div",
          { staticClass: "bg-white", staticStyle: { height: "100%" } },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                ref: "mainTable",
                staticStyle: { width: "100%" },
                attrs: {
                  height: "calc(100% - 52px)",
                  data: _vm.list,
                  border: "",
                  fit: "",
                  "highlight-current-row": "",
                },
                on: {
                  "row-click": _vm.rowClick,
                  "selection-change": _vm.handleSelectionChange,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { "min-width": "50px", label: "消息分类" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.typeName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "50px", label: "发送人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.fromName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "80px", label: "状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.toStatus == 0
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("未读"),
                              ])
                            : _vm._e(),
                          scope.row.toStatus == 1
                            ? _c("el-tag", [_vm._v("已读")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "180px", label: "消息内容" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "80px", label: "消息时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "操作",
                    width: "230",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.toStatus == 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRead(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("标记已读 ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete([scope.row])
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.page,
                limit: _vm.listQuery.limit,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "limit", $event)
                },
                pagination: _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }